import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsIcon from '@mui/icons-material/Directions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#fff", // Customize cell background color
    fontWeight: "bold", // Customize cell text styling
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#F4F4F4", // Customize cell background color
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#E0E0E0",
    },
    border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
    marginBottom: "10px",
}));

const Contract = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const confirm = useConfirm();

    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const [pay, setpay] = useState([
        {
            date: "26.7.3",
            contractid: "#635476",
            name:"Damon",
            status: "Signed",
        },
        {
            date: "2.8.3",
            contractid: "#635476",
            name:"Amy",
            status: "Signed",
        },
        {
            date: "26.7.3",
            contractid: "#635476",
            name:"Andy",
            status: "Pending",
        },
        {
            date: "2.8.3",
            contractid: "#635476",
            name:"Mellisa",
            status: "Signed",
        },
        {
            date: "26.7.3",
            contractid: "#635476",
            name:"Jamie",
            status: "Pending",
        },
        {
            date: "2.8.3",
            contractid: "#635476",
            name:"Chris",
            status: "Signed",
        }
    ] as any);
     
    
    return (
        <>
            <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
            <Sidebar>
                <div className="py-6 px-4 flex flex-col gap-8">
                    <div className="w-full flex justify-between items-center">
                        <div className=" flex flex-col gap-2">
                            <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                            {t('DistributerRoute.contractAdminPage.title')} 
                            </h1>
                            <p className="inter tracking-tight text-sm color-[#23282D]">
                            {t('DistributerRoute.contractAdminPage.description')}                             </p>
                        </div>
                        <button
                            className="flex gap-1 h-8 px-2 items-center justify-center bg-white   rounded-lg poppins leading-6 "
                            style={{
                                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
                            }}
                            onClick={() => navigate("/place-order")}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12.1094 7V17M17.1094 12L7.10938 12"
                                    stroke="#4B4B4B"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <p className="wrap break-words"> {t('DistributerRoute.contractAdminPage.newContractButton')} </p>
                        </button>
                    </div>
                    
                            <div className="w-full bg-white p-4 rounded shadow ">
                                <Table
                                    sx={{
                                        borderSpacing: "0 15px !important",
                                        borderCollapse: "separate !important",
                                    }}
                                >
                                    <TableHead>
                                        <CustomTableHeadRow>
                                            <CustomTableHeadCell>{t('DistributerRoute.contractAdminPage.tableHeaders.date')}</CustomTableHeadCell>
                                            <CustomTableHeadCell>{t('DistributerRoute.contractAdminPage.tableHeaders.orderId')}</CustomTableHeadCell>
                                            <CustomTableHeadCell>{t('DistributerRoute.contractAdminPage.tableHeaders.recipient')}</CustomTableHeadCell>
                                            <CustomTableHeadCell></CustomTableHeadCell>
                                        </CustomTableHeadRow>
                                    </TableHead>
                                    <TableBody>
                                        {pay.map((pay: any) => {
                                            return (
                                                <CustomTableBodyRow
                                                    sx={{
                                                        marginBottom: "10px !important",
                                                    }}
                                                >
                                                    <CustomTableBodyCell
                                                        sx={{
                                                            borderTopLeftRadius: "10px",
                                                            borderBottomLeftRadius: "10px",
                                                        }}
                                                    >
                                                        {pay?.date}
                                                    </CustomTableBodyCell>
                                                    <CustomTableBodyCell>
                                                        {pay?.contractid}
                                                    </CustomTableBodyCell>
                                                    <CustomTableBodyCell>
                                                        {pay?.name}
                                                    </CustomTableBodyCell>


                                                    <CustomTableHeadCell>
                                                        <button className="bg-white w-full py-1 font-light px-2 text-sm text-black poppins leading-6 rounded border-2 flex gap-10 ">
        
                                                            <div className={`flex gap-2 items-center`} >
                                                                <div
                                                                    className={`p-1 h-1 w-1 rounded-full  ${pay?.status === "Signed"
                                                                            ? "bg-[#93E277] "
                                                                            : pay?.status === "Cancelled"
                                                                                ? "bg-[#EF8787]"
                                                                            : pay?.status === "In Progress"
                                                                            ? "bg-[#5eb7dc]"
                                                                                : "bg-[#EECB98]"
                                                                        } `}
                                                                ></div>
                                                                {pay?.status}
                        
                                                            </div>
                                                        </button>
                                                    </CustomTableHeadCell>
                                                    
                                                </CustomTableBodyRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    
                
            </Sidebar>
        </>
    );
};

export default Contract;

