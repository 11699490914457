import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import axios from "axios";
import { backendUrl } from "../../../utils/axios";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

type FormProps = {
  user: string;
  amount: string;
  invoice: string;
  invoiceNo: string;
  type: string;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CreatePayment = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [distributorOptions, setDistributorOptions] = useState([] as any);
  const [file, setFile] = useState(null);

  const { t } = useTranslation();

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const defaultValues: FormProps = {
    user: "",
    amount: "",
    invoice: "",
    invoiceNo: "",
    type: "Admin",
  };

  const getDistibutors = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "user/getDistributors");
      console.log(data);
      setDistributorOptions(
        data?.users.map((item: any) => ({ label: item?.name, id: item?._id }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };
  useEffect(() => {
    getDistibutors();
  }, []);

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (!file) return toastMessage("Please select a file", "error");

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const { data: result } = await axios.post(
        `${backendUrl}/upload/singleFileUpload`,
        formData
      );

      values.invoice = result.fileUrl;
      values.type = "Admin";

      const { data } = await callAxios("post", "payment/createPayment", values);
      console.log(data);
      toastMessage("Payment Request Generated Successfully", "success");
      setLoading(false);
      navigate("/admin/payments");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.createPayment.pageTitle")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.createPayment.pageDescription")}{" "}
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded">
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full flex justify-center">
                  <h6 className="text-[#23282D] text-2xl poppins font-medium underline">
                    {t("adminRoute.createPayment.sectionTitle")}
                  </h6>
                </div>
                <div className="w-full grid grid-cols-3 gap-8 ">
                  <div className="w-full">
                    <SelectElement
                      options={distributorOptions}
                      name="user"
                      fullWidth
                      required
                      label={t("adminRoute.createPayment.selectDistributor")}
                      size="small"
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="amount"
                      fullWidth
                      required
                      label={t("adminRoute.createPayment.amount")}
                      size="small"
                      type="number"
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="invoiceNo"
                      fullWidth
                      required
                      label={t("adminRoute.createPayment.invoiceNo")}
                      size="small"
                    />
                  </div>

                  {/* <div className="w-full">
                    <SelectElement
                      options={distributorOptions}
                      name="user"
                      fullWidth
                      required
                      label="Select Distributor"
                      size="small"
                    />
                  </div> */}
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="poppins text-sm leading-2 text-gray-400">
                    {t("adminRoute.createPayment.uploadInvoice")}
                  </p>
                  <div className="box-border h-52 w-78 p-4 border-2 border-dotted border-gray-300 flex flex-col items-center justify-center ">
                    <div className="flex gap-2 items-center ">
                      <p className="poppins text-sm leading-2 text-gray-400">
                        {t("adminRoute.createPayment.dragFile")}{" "}
                      </p>
                      <Button
                        className=""
                        component="label"
                        variant="contained"
                        size="small"
                        sx={{ color: "white" }}
                      >
                        {t("adminRoute.createPayment.browse")}
                        <VisuallyHiddenInput
                          type="file"
                          required
                          accept="application/pdf"
                          onChange={handleFileChange}
                        />
                      </Button>
                    </div>

                    <br />
                    <div className="poppins text-sm leading-2 text-gray-400">
                      {" "}
                      {t("adminRoute.createPayment.fileFormats")}
                    </div>
                  </div>
                </div>
                <div className="w-full  flex justify-end items-center">
                  <button className="rounded bg-primary py-2 px-16 poppins text-white ">
                    {t("adminRoute.createPayment.submitButton")}
                  </button>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreatePayment;
