import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import { callAxios } from "../../utils/axios";

type FormProps = {
  name: string;
  role: string;
  branch: string;
  function_: string;
  username: string;
  remarks: string;
  email: string;
};

const NewPayment = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const defaultValues: FormProps = {
    name: "",
    role: "",
    branch: "",
    function_: "",
    username: "",
    remarks: "",
    email: "",
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await callAxios("post", "user/createUser", values);
      console.log(data);
      toastMessage("User Created Successfully", "success");
      setLoading(false);
      navigate("/distributor/roles");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                Make A Payment
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              Your secure and hassle-free portal for swift and convenient transactions, ensuring a seamless and user-friendly payment experience.
              </p>
            </div>
       
          </div>
          <div className="w-full bg-[#F9F9F9] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-center">
              <p className="poppins text-lg font-light underline">
                Enter Payment Details
              </p>
            </div>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="name"
                      label="Recipient Name"
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      validation={{
                        validate: (value) => {
                          console.log(value);
                          let regex = /^(?=.*[a-zA-Z]).+$/;
                          if (regex.test(value)) {
                            return true;
                          } else {
                            return "Name must contains a character. ";
                          }
                        },
                      }}
                    />
                  </div>
                  
                </div>
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <SelectElement
                      name="role"
                      label="Payment Type"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={[
                        { label: "Distributor", id: "distributor" },
                        { label: "Dealer", id: "dealer" },
                      ]}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      name="branch"
                      required
                      label="Select Branch"
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={[
                        { id: "Sonipat", label: "Sonipat" },
                        { id: "Panipat", label: "Panipat" },
                      ]}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      name="function_"
                      label= "Function"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={[
                        { label: "Test 1", id: "Test 1" },
                        { label: "Test 2", id: "Test 2" },
                      ]}
                    />
                  </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="username"
                      label="Choose Username"
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      validation={{
                        validate: (value) => {
                          console.log(value);
                          let regex = /^(?=.*[a-zA-Z]).+$/;
                          if (regex.test(value)) {
                            return true;
                          } else {
                            return "Name must contains a character. ";
                          }
                        },
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="email"
                      label="Select Password"
                      type="email"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="email"
                      label="Re-enter Password"
                      type="email"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <p className="poppins text-light ">Remarks (if any)</p>
                  <TextFieldElement
                    name="remarks"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              </div>
              <div className="w-full mt-20 flex justify-center items-center">
                <button className="rounded bg-[#92BE5A] py-2 px-6 poppins text-white ">
                  Submit
                </button>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default NewPayment;
