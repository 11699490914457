import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { callAxios } from "../../../../utils/axios";
import { toastMessage } from "../../../../utils/toast";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { backendUrl } from "../../../../utils/axios";
import Loading from "react-fullscreen-loading";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type FormProps = {
  skuName: string;
  skuCode: string;
  taxPercentage: number;
  category: string;
  description: string;
};

const ProductDetails = ({
  step,
  setStep,
  formData,
  setFormData,
  existingData,
}: any) => {
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const defaultValues: FormProps = {
    skuName: existingData?.skuName || "",
    skuCode: existingData.skuCode,
    taxPercentage: existingData.taxPercentage,
    category: existingData.category,
    description: existingData.description,
  };

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const onSubmit = async (values: FormProps) => {
    try {
      let data;
      if (file) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        data = await axios.post(
          `${backendUrl}/upload/singleFileUpload`,
          formData
        );
        console.log(data);
      }

      setFormData({
        ...formData,
        ...values,
        image: file ? data.data?.fileUrl : existingData.image,
      });
      setStep(step + 1);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAllCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "category/getAllCategories");
      console.log(data);
      setCategories(
        data?.categories.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="w-full flex flex-col gap-6 py-4 ">
        <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          Product Details
        </h6>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="flex flex-col gap-8 w-full">
            <div className="w-full grid grid-cols-3 gap-16 ">
              <div className="w-full flex flex-col gap-1">
                <TextFieldElement
                  name="skuName"
                  placeholder="SKU Name"
                  required
                  fullWidth
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <TextFieldElement
                  name="skuCode"
                  placeholder="SKU Code"
                  required
                  fullWidth
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <TextFieldElement
                  name="taxPercentage"
                  placeholder="Tax Percentage"
                  required
                  type="number"
                  fullWidth
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-3 gap-16 ">
              <div className="w-full flex flex-col gap-1">
                <SelectElement
                  name="category"
                  label="Product Category"
                  required
                  fullWidth
                  options={categories}
                />
              </div>
              <div className="w-full flex flex-col gap-1 col-span-2">
                <TextFieldElement
                  name="description"
                  placeholder="Product Short Description"
                  required
                  fullWidth
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-3 gap-16 ">
              <div className="col-span-2 w-full flex items-center justify-center py-12 border-dotted border-gray-300 border ">
                <div className="flex flex-col gap-6 justify-center items-center">
                  {existingData.image && (
                    <img
                      src={existingData.image}
                      alt="image"
                      className="w-12 h-12"
                    />
                  )}
                  <div className="flex gap-2 items-center ">
                    <p className="poppins text-sm leading-2 text-gray-400">
                      Drag Your File or{" "}
                    </p>
                    <Button
                      className=""
                      component="label"
                      variant="contained"
                      size="small"
                      sx={{ color: "white" }}
                    >
                      Browse
                      <VisuallyHiddenInput
                        type="file"
                        required
                        accept="image/* , .pdf"
                        onChange={handleFileChange}
                      />
                    </Button>
                  </div>
                  <div className="poppins text-sm leading-2 text-gray-400">
                    {" "}
                    (pdf, png, jpeg, svg)
                  </div>
                </div>
              </div>
            </div>
            <button
              className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
              type="submit"
            >
              Next
            </button>
          </div>
        </FormContainer>
      </div>
    </>
  );
};

export default ProductDetails;
