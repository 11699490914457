import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";
import { useConfirm } from "material-ui-confirm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "transparent", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#000",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const PaymentsTable = () => {

  const {t} = useTranslation()
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [payments, setPayments] = useState([] as any);

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `payment/getPaymentsByUser?page=${page}&limit=10`
      );
      console.log(data);
      setPayments(data?.payments);
      setLoading(false);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllPayments();
  }, [checked, page]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('DistributerRoute.paymentsPage.title')} 
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('DistributerRoute.paymentsPage.description')}               </p>
            </div>
          </div>
          <div className="w-full bg-white p-4 rounded shadow ">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell> {t('DistributerRoute.paymentsPage.tableHeaders.invoiceNo')}  </CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.paymentsPage.tableHeaders.amount')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.paymentsPage.tableHeaders.date')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('DistributerRoute.paymentsPage.tableHeaders.status')}</CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {payments?.map((payment: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                      key={payment?.id}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {payment?.invoiceNo}
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        {payment?.amount}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {moment(payment?.createdAt).format("DD.MM.YYYY")}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <div className={`flex gap-2 items-center `}>
                          <div
                            className={`p-1 h-1 w-1 rounded-full ${
                              payment?.status === "Paid"
                                ? "bg-[#93E277]"
                                : payment?.status === "Pending"
                                ? "bg-[#EF8787]"
                                : "bg-[#EECB98]"
                            } `}
                          ></div>
                          {payment?.status}
                        </div>
                      </CustomTableBodyCell>

                      <CustomTableHeadCell>
                        <div className="w-full flex gap-3">
                          <a
                            href={payment?.invoice}
                            target="_blank"
                            className="bg-[#719FE5]  cursor-pointer w-fit py-1 font-light px-2 text-sm text-white poppins leading-6 rounded"
                          >
                           {t('DistributerRoute.paymentsPage.tableActions.viewInvoice')}
                          </a>

                          {payment?.status === "Pending" ? (
                            <a
                              href={payment?.payment_url}
                              target="_blank"
                              className="bg-red-400 w-fit cursor-pointer  py-1 font-light px-2 text-sm text-white poppins leading-6 rounded"
                            >
                            {t('DistributerRoute.paymentsPage.tableActions.pay')}
                            </a>
                          ) : (
                            <button
                              disabled
                              className="bg-green-400 cursor-pointer w-fit py-1 font-light px-2 text-sm text-white poppins leading-6 rounded"
                            >
                                {t('DistributerRoute.paymentsPage.tableActions.paid')}
                            </button>
                          )}
                        </div>
                      </CustomTableHeadCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default PaymentsTable;
