import React, { useState, useEffect } from "react";
import { FormContainer, TextFieldElement,SelectElement } from "react-hook-form-mui";

type FormProps = {
  shippingAddress: string;
  shippingZip: number;
  shippingCity: string;
  shippingState: string;
  shippingCountry: string;
  shippingRegion: string;
  billingAddress: string;
  billingZip: number;
  billingCity: string;
  billingState: string;
  billingCountry: string;
};

const AddressDetails = ({ step, setStep, formData, setFormData,existingData }: any) => {
  const defaultValues: FormProps = {
    shippingAddress: existingData.shippingAddress,
    shippingZip: existingData.shippingZip,
    shippingCity: existingData.shippingCity,
    shippingState: existingData.shippingState,
    shippingCountry: existingData.shippingCountry,
    billingAddress: existingData.billingAddress,
    billingZip: existingData.billingZip,
    billingCity: existingData.billingCity,
    billingState: existingData.billingState,
    billingCountry: existingData.billingCountry,
    shippingRegion:  existingData.shippingRegion,
  };
  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  }; 

  return (
    <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
      <div className="w-full flex flex-col gap-6 py-4 ">
        <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          Shippping Address
        </h6>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-1 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingAddress"
                placeholder="Enter Address"
                required
                fullWidth
              />
            </div>
         
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingZip"
                placeholder="Zip Code"
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingCity"
                placeholder="City"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingState"
                placeholder="State"
                required
                fullWidth
              />
            </div>

          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingCountry"
                placeholder="Country"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingRegion"
                placeholder="Region"
                required
                fullWidth
              />
            </div>
         

          </div>
        </div>
        <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          Billing Address
        </h6>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-1 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingAddress"
                placeholder="Enter Address"
                required
                fullWidth
              />
            </div>
         
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingZip"
                placeholder="Zip Code"
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingCity"
                placeholder="City"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingState"
                placeholder="State"
                required
                fullWidth
              />
            </div>

          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingCountry"
                placeholder="Country"
                required
                fullWidth
              />
            </div>
        

          </div>
        </div>
        <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Next
          </button>
      </div>
    </FormContainer>
  );
};

export default AddressDetails;
