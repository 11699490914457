export const distributorFunctions = [
  {
    name: "Dashboard",
    frenchName: "Tableau de bord",
    route: "/distributor/dashboard",
    logo: "/assets/Icons/dashboard.svg",
  },
  {
    name: "Sales Performance",
    frenchName: "Performance des ventes",
    route: "/distributor/sales-performance",
    logo: "/assets/Icons/sales-performance.svg",
  },
  {
    name: "Account Management",
    frenchName: "Gestion du compte",
    route: "/distributor/account-management",
    logo: "/assets/Icons/account-management.svg",
  },
  {
    name: "Dealer Management",
    frenchName: "Gestion des concessionnaires",
    route: "/distributor/roles",
    logo: "/assets/Icons/role-management.svg",
  },
  {
    name: "Payments",
    frenchName: "Paiements",
    route: "/distributor/payments",
    logo: "/assets/Icons/payments.svg",
  },
  {
    name: "My Contracts",
    frenchName: "Mes contrats",
    route: "/distributor/my-contracts",
    logo: "/assets/Icons/dashboard.svg",
  },
  {
    name: "Appointments",
    frenchName: "Rendez-vous",
    route: "/distributor/appointment-management",
    logo: "/assets/Icons/appointments.svg",
  },
  {
    name: "Support",
    frenchName: "Support",
    route: "/distributor/support-ticket",
    logo: "/assets/Icons/support.svg",
  },
  {
    name: "Manage Files",
    frenchName: "Gérer les fichiers",
    route: "/distributor/manage-files",
    logo: "/assets/Icons/manage-files.svg",
  },
  {
    name: "Resources",
    frenchName: "Ressources",
    route: "/distributor/resources",
    logo: "/assets/Icons/manage-files.svg",
  },
  {
    name: "Chat",
    frenchName: "Chat",
    route: "/distributor/admin-chat",
    logo: "/assets/Icons/support.svg",
  },
  {
    name: "Order Tracking",
    frenchName: "Suivi des commandes",
    route: "/distributor/order-management",
    logo: "/assets/Icons/order-tracking.svg",
  },
  {
    name: "Buy Products",
    frenchName: "Acheter des produits",
    route: "/distributor/buy-products",
    logo: "/assets/Icons/purchase-order.svg",
  },
  { name: "Settings", frenchName: "Paramètres", route: "/settings", logo: "/assets/Icons/settings.svg" },
];





export const adminFunctions = [
  {
    name: "Dashboard",
    frenchName: "Tableau de bord",
    route: "/admin/dashboard",
    logo: "/assets/Icons/dashboard.svg",
  },
  {
    name: "All Users",
    frenchName: "Tous les utilisateurs",
    route: "/admin/users",
    logo: "/assets/Icons/users.svg",
  },
  {
    name: "All Distributors",
    frenchName: "Tous les distributeurs",
    route: "/admin/all-distributors",
    logo: "/assets/Icons/distributors.svg",
  },
  {
    name: "Material Master",
    frenchName: "Gestion des stocks",
    route: "/admin/material-master",
    logo: "/assets/Icons/my-contracts.svg",
  },
  {
    name: "Material Quantity",
    frenchName: "Quantité de matériaux",
    route: "/admin/material-quantity",
    logo: "/assets/Icons/manage-files.svg",
  },
  {
    name: "Product Category",
    frenchName: "Catégorie de produits",
    route: "/admin/product-category",
    logo: "/assets/Icons/product-category.svg",
  },
  {
    name: "Create Product",
    frenchName: "Créer un produit",
    route: "/admin/create-product",
    logo: "/assets/Icons/create-product.svg",
  },
  {
    name: "Display Product",
    frenchName: "Afficher le produit",
    route: "/admin/display-product",
    logo: "/assets/Icons/display-product.svg",
  },
  {
    name: "Purchase Order",
    frenchName: "Bon de commande",
    route: "/admin/purchase-order",
    logo: "/assets/Icons/purchase-order.svg",
  },
  {
    name: "Marketing Campaigns",
    frenchName: "Campagnes marketing",
    route: "/admin/marketing-campaigns",
    logo: "/assets/Icons/payments.svg",
  },
  {
    name: "Live Chat",
    frenchName: "Chat en direct",
    route: "/admin/chat",
    logo: "/assets/Icons/support.svg",
  },
  {
    name: "Support Tickets",
    frenchName: "Tickets de support",
    route: "/admin/support-tickets",
    logo: "/assets/Icons/support.svg",
  },
  {
    name: "Appointments",
    frenchName: "Rendez-vous",
    route: "/admin/appointments",
    logo: "/assets/Icons/appointments.svg",
  },
  {
    name: "Contracts",
    frenchName: "Contrats",
    route: "/admin/contracts",
    logo: "/assets/Icons/my-contracts.svg",
  },
  {
    name: "Payments",
    frenchName: "Paiements",
    route: "/admin/payments",
    logo: "/assets/Icons/my-contracts.svg",
  },
  {
    name: "Settings",
    frenchName: "Paramètres",
    route: "/admin/settings",
    logo: "/assets/Icons/settings-admin.svg",
  },
];




