import React, { useState, useEffect } from "react";
import {
  FormContainer,
  RadioButtonGroup,
  TextFieldElement,
} from "react-hook-form-mui";

type FormProps = {
  bankAccountNo: string;
  ifscCode: string;
  micrCode: string;
  bankName: string;
  bankAddress: string;
  billingType: string;
  creditLimit: number;
  gracePeriod: number;
};

const PaymentDetails = ({ step, setStep, formData, setFormData,finalSubmit,existingData }: any) => {
  const [type, setType] = useState(existingData.billingType);

  const defaultValues: FormProps = {
    bankAccountNo: existingData.bankAccountNo,
    ifscCode: existingData.ifscCode,
    micrCode: existingData.micrCode,
    bankName: existingData.bankName,
    bankAddress: existingData.bankAddress,
    billingType: existingData.billingType,
    creditLimit: existingData.creditLimit,
    gracePeriod: existingData.gracePeriod,
  };

  const onSubmit = (values: FormProps) => {
    console.log(values);
    finalSubmit(values);

  };

  console.log(type);

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        Payment Details
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="bankAccountNo"
                placeholder="Enter Bank Account Number"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="ifscCode"
                placeholder="Enter IFSC Code"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="micrCode"
                placeholder="Enter MICR Code"
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="bankName"
                placeholder="Enter Bank Name"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1 col-span-2 ">
              <TextFieldElement
                name="bankAddress"
                placeholder="Enter Bank Address"
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full flex gap-8 items-center">
            <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
              Billing Type
            </h6>
            <RadioButtonGroup
              name="billingType"
              options={[
                {
                  id: "Bill to Bill",
                  label: "Bill to Bill",
                },
                {
                  id: "Bill to Credit",
                  label: "Bill to Credit",
                },
              ]}
              row
              onChange={(e) => {
                setType(e);
              }}
            />
          </div>
          {type === "Bill to Credit" && (
            <div className="w-full grid grid-cols-2 gap-16 ">
              <div className="w-full flex flex-col gap-1">
                <TextFieldElement
                  name="creditLimit"
                  placeholder="Credit Limit (in numbers)"
                  required
                  type="number"
                  fullWidth
                />
              </div>
              <div className="w-full flex flex-col gap-1 ">
                <TextFieldElement
                  name="gracePeriod"
                  placeholder="Grace Period (in days)"
                  required
                  type="number"
                  fullWidth
                />
              </div>
            </div>
          )}
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Continue
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default PaymentDetails;
