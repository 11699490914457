import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
  email: string;
  phone: number;
  role: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const AddUser = ({ checked, setChecked, setOpen }: any) => {
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([] as any);

  const {t} = useTranslation()

  const defaultValues: FormProps = {
    name: "",
    email: "",
    phone: undefined,
    role: "",
  };

  const getAllRoles = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "role/getAllRoles");
      console.log(data);
      setRoleOptions(
        data?.roles.map((item: any) => {
          return {
            id: item._id,
            label: item.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);


  const onSubmit = async (values: FormProps) => {

    try {
      setLoading(true);
      const { data } = await callAxios("post", "user/createUserByAdmin", values);
      console.log(data);
      toastMessage("User Created Successfully", "success");
      setLoading(false);
      setChecked(!checked);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  }

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="flex flex-col gap-4  w-full">
        <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        {t('adminRoute.usersPage.createUser.pageTitle')}
        </h3>
        <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
        {t('adminRoute.usersPage.createUser.pageDescription')}        </p>
        <FormContainer
          defaultValues={defaultValues}
          onSuccess={onSubmit}
        >
          <div className="w-full flex flex-col gap-8">
            <div className="w-full">
              <TextFieldElement
                name="name"
                label={t('adminRoute.usersPage.createUser.nameLabel')} 
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^(?=.*[a-zA-Z]).+$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Name must contains a character. ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="email"
                label={t('adminRoute.usersPage.createUser.emailLabel')} 
                type="email"
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="phone"
                label={t('adminRoute.usersPage.createUser.phoneLabel')} 
                type="number"
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^[0-9]{10}$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Phone enter a valid phone no ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full">
              <SelectElement
                name="role"
                label={t('adminRoute.usersPage.createUser.selectUserTypeLabel')} 
                options={roleOptions}
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <button className="rounded bg-primary py-2 px-6 poppins text-white w-1/2 ">
            {t('adminRoute.usersPage.createUser.submitButton')} 
            </button>
          </div>
        </FormContainer>
      </div>
    </Box>
  );
};

export default AddUser;
