import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";

type FormProps = {
  name: string;
  email: string;
  phone: number;
  role: string;
  userId: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const EditUser = ({ checked, setChecked, setOpen, existingUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([] as any);
  const defaultValues: FormProps = {
    name: existingUser?.name,
    email: existingUser?.email,
    phone: existingUser?.phone,
    role: existingUser?.permissionRole?._id,
    userId: existingUser?._id,
  };

  const getAllRoles = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "role/getAllRoles");
      console.log(data);
      setRoleOptions(
        data?.roles.map((item: any) => {
          return {
            id: item._id,
            label: item.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        "user/editUserByAdmin",
        values
      );
      console.log(data);
      toastMessage("User Updated Successfully", "success");
      setLoading(false);
      setChecked(!checked);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="flex flex-col gap-4  w-full">
        <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          Edit User
        </h3>
        <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
        Fine-tune user details with precision, ensuring a personalized and secure experience through seamless user management and customization.
        </p>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full flex flex-col gap-8">
            <div className="w-full">
              <TextFieldElement
                name="name"
                label="Name"
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^(?=.*[a-zA-Z]).+$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Name must contains a character. ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="email"
                label="Email"
                type="email"
                required
                disabled
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="phone"
                label="Phone"
                type="number"
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^[0-9]{10}$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Phone enter a valid phone no ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full">
              <SelectElement
                name="role"
                label="Select User Type"
                options={roleOptions}
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <button className="rounded bg-primary py-2 px-6 poppins text-white w-1/2 ">
              Submit
            </button>
          </div>
        </FormContainer>
      </div>
    </Box>
  );
};

export default EditUser;
