import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../utils/axios";
import Loading from "react-fullscreen-loading";
import { toastMessage } from "../../utils/toast";

import { styled } from "@mui/material/styles";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";

import Button from "@mui/material/Button";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import ModalImage from "react-modal-image";
type FormProps = {
  title: string;
  category: string;
  description: string;
};

const SupportTicket = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [active, setActive] = useState(-1);
  const [activeTickets, setActiveTickets] = useState([] as any);
  const [closedTickets, setClosedTickets] = useState([] as any);
  const [inputValues, setInputValues] = useState<FormProps>({
    title: "",
    category: "",
    description: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e: any) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("files", selectedImages[i]);
      }

      const { data } = await axios.post(
        `${backendUrl}/upload/multipleFileUpload`,
        formData
      );

      console.log(data);
      const { title, category, description } = values;

      const { data: response } = await callAxios(
        "post",
        "support/createSupportTicket",
        {
          title,
          category,
          description,
          images: data?.fileUrls,
        }
      );
      console.log(response);
      setChecked(!checked);
      setLoading(false);
      setInputValues({
        title: "",
        category: "",
        description: "",
      });
      setSelectedImages([]);

      toastMessage("Ticket Raised Successfully", "success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const getAllSupportTickets = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        "support/getSupportTicketsOfUser"
      );
      console.log(data);
      setActiveTickets(data?.activeTickets);
      setClosedTickets(data?.closedTickets);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllSupportTickets();
  }, [checked]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="p-12 flex flex-col gap-8  ">
          {/* Header */}
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                Support Tickets
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              Your central hub for efficient issue resolution, empowering seamless communication and effective management of customer support requests.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 bg-white p-4 rounded shadow ">
            <div className="w-full  flex flex-col gap-8 px-8 ">
              <div className="w-full gap-2 flex  ">
                <button
                  className=" outline-none px-1"
                  onClick={() => {
                    setActiveTab(1);
                    setActive(-1);
                  }}
                >
                  <div className={activeTab === 1 ? "text1" : "text2"}>
                    Active
                  </div>
                </button>
                <button
                  className="outline-none px-1"
                  onClick={() => {
                    setActiveTab(2);
                    setActive(-1);
                  }}
                >
                  <div className={activeTab === 2 ? "text1" : "text2"}>
                    Closed
                  </div>
                </button>
              </div>
              <div className="w-full flex flex-col gap-3 overflow-y-scroll max-h-[80vh] ">
                {activeTab === 1
                  ? activeTickets?.map((ticket: any, index: number) => {
                      return (
                        <div
                          className="box-border rounded-2xl w-full py-3  px-3 border-2 flex flex-col gap-3 "
                          key={ticket._id}
                        >
                          {/* Card Header */}
                          <div className="w-full flex  justify-between items-center  ">
                            <p className="text3 ">{ticket?.title}</p>
                            <div className="flex gap-2 items-center ">
                              {ticket?.images.length > 0 && (
                                <div className="bg-[#719FE510] rounded-xl flex gap-2 p-1 px-2 ">
                                  <img src="/assets/Icons/image-icon.svg" />
                                  <p className="poppins leading-8">
                                    {ticket?.images?.length > 1 &&
                                      "+" + Number(ticket?.images?.length - 1)}
                                  </p>
                                </div>
                              )}
                              <img
                                src="/assets/Icons/CaretCircleUp.svg"
                                className={`cursor-pointer ${
                                  active === index && "rotate-180"
                                } `}
                                onClick={() =>
                                  active === index
                                    ? setActive(-1)
                                    : setActive(index)
                                }
                              />
                            </div>
                          </div>
                          <div className="w-full flex flex-col gap-1">
                            <h6 className="text4 break-words ">
                              {ticket?.category}
                            </h6>
                            {active !== index ? (
                              <p className="text5">
                                {ticket?.description.slice(0, 70)}
                                <span
                                  className="text-5 font-semibold cursor-pointer "
                                  onClick={() => setActive(index)}
                                >
                                  {" "}
                                  ....See more
                                </span>{" "}
                              </p>
                            ) : (
                              <div className="w-full flex flex-col gap-4">
                                <p className="text5">{ticket?.description}</p>
                                <hr />
                                <div className="flex gap-4 px-4">
                                  {ticket?.images.map((image: any) => {
                                    return (
                                      <div className="w-20 h-full rounded cursor-pointer overflow-hidden">
                                        <ModalImage
                                          small={image}
                                          large={image}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : closedTickets?.map((ticket: any, index: number) => {
                      return (
                        <div
                          className="box-border rounded-2xl w-full py-3  px-3 border-2 flex flex-col gap-3 "
                          key={ticket._id}
                        >
                          {/* Card Header */}
                          <div className="w-full flex  justify-between items-center  ">
                            <p className="text3 ">{ticket?.title}</p>
                            <div className="flex gap-2 items-center ">
                              {ticket?.images.length > 0 && (
                                <div className="bg-[#719FE510] rounded-xl flex gap-2 p-1 px-2 ">
                                  <img src="/assets/Icons/image-icon.svg" />
                                  <p className="poppins leading-8">
                                    {ticket?.images?.length > 1 &&
                                      "+" + Number(ticket?.images?.length - 1)}
                                  </p>
                                </div>
                              )}
                              <img
                                src="/assets/Icons/CaretCircleUp.svg"
                                className={`cursor-pointer ${
                                  active === index && "rotate-180"
                                } `}
                                onClick={() =>
                                  active === index
                                    ? setActive(-1)
                                    : setActive(index)
                                }
                              />
                            </div>
                          </div>
                          <div className="w-full flex flex-col gap-1">
                            <h6 className="text4 break-words ">
                              {ticket?.category}
                            </h6>
                            {active !== index ? (
                              <p className="text5">
                                {ticket?.description.slice(0, 70)}
                                <span
                                  className="text-5 font-semibold cursor-pointer "
                                  onClick={() => setActive(index)}
                                >
                                  {" "}
                                  ....See more
                                </span>{" "}
                              </p>
                            ) : (
                              <div className="w-full flex flex-col gap-4">
                                <p className="text5">{ticket?.description}</p>
                                <hr />
                                <div className="flex gap-4 px-4">
                                  {ticket?.images.map((image: any) => {
                                    return (
                                      <div className="w-20 h-full rounded cursor-pointer overflow-hidden">
                                        <ModalImage
                                          small={image}
                                          large={image}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <hr />
                                <div className="flex flex-col gap-1 p-2 rounded nbg-blue-100 ">
                                  <div className="flex gap-1 items-center px-4 ">
                                    <img
                                      alt="icon"
                                      src="/assets/Icons/ChatCircleText.svg"
                                    />
                                    <h6 className="poppins text-sm font-semibold tracking-tight">
                                      Resolution
                                    </h6>
                                  </div>
                                  <p className="text5 ">{ticket?.resolution}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="w-full border-l px-8 py-12 border-grey-200 ">
              <div className="flex gap-4 items-center ">
                <img
                  src="/assets/Icons/user-avatar.png"
                  className="rounded-full w-12 h-12 object-cover "
                />
                <div className="text6">Raise A ticket</div>
              </div>
              <FormContainer
                // defaultValues={inputValues}
                onSuccess={onSubmit}
                resetOptions={{
                  keepValues: false,
                  keepIsSubmitted: false,
                  keepTouched: false,
                  keepDirty: false,
                  keepIsValid: false,
                  keepSubmitCount: false,
                }}
                shouldFocusError={true}
                values={inputValues}
              >
                <div className="flex flex-col gap-8 w-full">
                  <div className="w-full grid grid-cols-2 gap-6 ">
                    <div className="w-full">
                      <p className="text7 ">
                        Ticket Title*
                        <br />
                      </p>
                      <TextFieldElement
                        name="title"
                        placeholder="Title"
                        required
                        onChange={(e) =>
                          setInputValues({
                            ...inputValues,
                            title: e.target.value,
                          })
                        }
                        value={inputValues.title}
                        fullWidth
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>

                    <div className="w-full">
                      <p className="text7 ">
                        Category*
                        <br />
                      </p>
                      <SelectElement
                        name="category"
                        placeholder="Other"
                        fullWidth
                        value={inputValues.category}
                        onChange={(e) => {
                          setInputValues({ ...inputValues, category: e });
                        }}
                        required
                        sx={{
                          backgroundColor: "#fff",
                        }}
                        options={[
                          {
                            id: "Technical Issues",
                            label: "Technical Issues",
                          },
                          {
                            id: "Billing and Payments",
                            label: "Billing and Payments",
                          },
                          {
                            id: "Product or Service Information",
                            label: "Product or Service Information",
                          },
                          {
                            id: "Account Management",
                            label: "Account Management",
                          },
                          {
                            id: "General Inquiries",
                            label: "General Inquiries",
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className=" flex flex-col gap-8 w-full">
                    <div className="w-full grid grid-cols-1 gap-8  ">
                      <p className="text7 ">
                        Description*
                        <br />
                        <TextFieldElement
                          className="my-0.5"
                          name="description"
                          multiline
                          placeholder="Type Here"
                          value={inputValues.description}
                          rows={3}
                          fullWidth
                          required
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              description: e.target.value,
                            })
                          }
                          sx={{
                            backgroundColor: "#fff",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <p className="text7 ">
                  Upload
                  <br />
                </p>
                <div className="w-full flex flex-col">
                  <div className="box-border w-full rounded my-2 p-4 border-2 border-dashed flex flex-col justify-center items-center py-8 ">
                    <div className="text-gray-400 ">
                      <div>
                        Drag Your File or{"  "}
                        <Button
                          className=""
                          component="label"
                          variant="contained"
                          style={{
                            color: "white",
                            textTransform: "none",
                          }}
                          size="small"
                        >
                          Browse
                          <VisuallyHiddenInput
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {selectedImages.length > 0 && (
                    <p className="text-sm text-primary ">
                      {selectedImages.length} files selected
                    </p>
                  )}
                </div>
                <div className="w-full mt-20 flex justify-center items-center">
                  <button className="rounded bg-[#719FE5] py-2 px-16 poppins text-white ">
                    Submit
                  </button>
                </div>
              </FormContainer>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default SupportTicket;
