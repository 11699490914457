import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  AutocompleteElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import { callAxios } from "../../utils/axios";
import { distributorFunctions } from "../../constants/FunctionConstants";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
  role: string;
  branch: string;
  function_: string[];
  username: string;
  remarks: string;
  email: string;
  createdByRole: string;
};

const AddUser = () => {
  const {t} =useTranslation()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState([] as any)

  const functionOptions = distributorFunctions.map((item) => {
    return {
      id: item.name,
      label: item.name,
    };
  });

  const defaultValues: FormProps = {
    name: "",
    role: "",
    branch: "",
    function_: [],
    username: "",
    remarks: "",
    email: "",
    createdByRole: "",
  };
  const roles = [
    { label: "Manager", id: "Manager" },
    { label: "Supervisor", id: "Supervisor" },
  ];

  const onSubmit = async (values: FormProps) => {
    values.function_ = values.function_.map((item: any) => {
      return item.id;
    });
    values.createdByRole = "Distributor";
    console.log(values);

    try {
      setLoading(true);
      const { data } = await callAxios("post", "user/createUser", values);
      console.log(data);
      toastMessage("User Created Successfully", "success");
      setLoading(false);
      navigate("/distributor/roles");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };


  const getAddresses = async () => {
    setLoading(true);
    try {
      const { data } = await callAxios("get", "address/getAddresses");
  
      console.log(data);
      

      setAddressOptions(
        data?.addresses?.map((item: any) => {
          return {
            id: item._id,
            label: item.name,
          };
        })
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAddresses();
  },[])


  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
               {t('DistributerRoute.addRolePage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('DistributerRoute.addRolePage.description')}              </p>
            </div>
          </div>
          <div className="w-full bg-[#F9F9F9] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-center">
              <p className="poppins text-lg font-light underline">
              {t('DistributerRoute.addRolePage.createRoleLabel')}  
              </p>
            </div>
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="name"
                      label={t('DistributerRoute.addRolePage.form.labels.name')}  
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      validation={{
                        validate: (value) => {
                          console.log(value);
                          let regex = /^(?=.*[a-zA-Z]).+$/;
                          if (regex.test(value)) {
                            return true;
                          } else {
                            return "Name must contains a character. ";
                          }
                        },
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldElement
                      name="email"
                      label={t('DistributerRoute.addRolePage.form.labels.email')}  
                      type="email"
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <SelectElement
                      name="role"
                      label={t('DistributerRoute.addRolePage.form.labels.role')}  
                      fullWidth
                      required
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={roles}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      name="branch"
                      required
                      label={t('DistributerRoute.addRolePage.form.labels.branch')}  
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      options={addressOptions}
                      
                    />
                  </div>
                  <div className="w-full">
                    <AutocompleteElement
                      name="function_"
                      label={t('DistributerRoute.addRolePage.form.labels.function')}  
                      required
                      multiple
                      options={functionOptions}
                      autocompleteProps={{
                        sx: {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-16  ">
                  <div className="w-full">
                    <TextFieldElement
                      name="username"
                      label={t('DistributerRoute.addRolePage.form.labels.username')}  
                      required
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      validation={{
                        validate: (value) => {
                          console.log(value);
                          let regex = /^(?=.*[a-zA-Z]).+$/;
                          if (regex.test(value)) {
                            return true;
                          } else {
                            return "Name must contains a character. ";
                          }
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <p className="poppins text-light ">{t('DistributerRoute.addRolePage.form.labels.remarks')} </p>
                  <TextFieldElement
                    name="remarks"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              </div>
              <div className="w-full mt-20 flex justify-center items-center">
                <button className="rounded bg-[#92BE5A] py-2 px-6 poppins text-white ">
                {t('DistributerRoute.addRolePage.form.submitButtonText')} 
                </button>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AddUser;
