import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DonutChart from "react-donut-chart";
import { TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { callAxios } from "../../../utils/axios";
import moment from "moment";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "black",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const data = [
  {
    name: "Page A",
    uv: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Dashboard = () => {
  const [ordersData, setOrdersData] = useState({} as any);
  const [distributorData, setDistributorData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [ordersFilter, setOrdersFilter] = useState("last7Days");
  const [distributorFilter, setDistributorFilter] = useState("last7Days");
  const [graphData, setGraphData] = useState([] as any);
  const [checked, setChecked] = useState(false);
  const [orders, setOrders] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {t} = useTranslation()

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `order/getAllOrders?limit=3&page=${page}`
      );
      console.log(data);
      setOrders(data?.orders);
      setTotalPages(data?.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [checked,page]);

  const getOrdersData = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/getOrdersData?interval=${ordersFilter}`
      );
      console.log(data);
      setOrdersData(data);
        
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getDistributorData = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/getDistributorData?interval=${distributorFilter}`
      );
      console.log(data); 
      setDistributorData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getOrderGraphData = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", `dashboard/orderGraphData`);
      console.log(data);
      setGraphData(data.graphData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderGraphData();
  }, []);

  useEffect(() => {
    getDistributorData();
  }, [distributorFilter]);

  React.useEffect(() => {
    getOrdersData();
  }, [ordersFilter]);

  return (
    <Sidebar>
      <div className="py-6 px-4 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
            {t('adminRoute.dashboardPg.dashboard.title')}
          </h1>
          <p className="inter tracking-tight text-sm color-[#23282D]">
          {t('adminRoute.dashboardPg.dashboard.subtitle')}
          </p>
        </div>

        <div className="w-full grid grid-cols-12 gap-12">
          {/* Left Container */}
          <div className="col-span-7 flex flex-col gap-3 w-full ">
            <div className="w-full flex gap-8 ">
              <div className="flex flex-col w-full  p-4 rounded-2xl bg-[#3D3D3D] gap-1 ">
                <div className="flex w-full justify-between">
                  <h6 className="poppins text-3xl font-medium tracking-tight leading-8 text-white">
                    {ordersData?.orders}
                  </h6>
                  <div className=" flex gap-1 items-center  ">
                    <select
                      className="poppins cursor-pointer text-sm leading-8 text-white bg-[#3D3D3D] border-none outline-none"
                      value={ordersFilter}
                      onChange={(e) => setOrdersFilter(e.target.value)}
                    >
                      <option value="last7Days">{t('adminRoute.dashboardPg.orders.date')}</option>
                      <option value="thisMonth">{t('adminRoute.dashboardPg.orders.thisMonth')}</option>
                      <option value="thisYear">{t('adminRoute.dashboardPg.orders.thisYear')}</option>
                    </select>
                  </div>
                </div>
                <p className="text-white poppins text-lg leading-8 tracking-tight">
                {t('adminRoute.dashboardPg.orders.completed')}
                </p>
                <p className="text-sm poppins leading-8 tracking-tight text-white">
                  {ordersData?.dateText}
                </p>
              </div>
              <div className="flex flex-col w-full text-white  p-4 rounded-2xl bg-[#5F7ADA] gap-1 ">
                <div className="flex w-full justify-between">
                  <h6 className="poppins text-3xl font-medium tracking-tight leading-8">
                    {distributorData?.distributors}
                  </h6>
                  <div className=" flex gap-1 items-center  ">
                    <select
                      className="poppins cursor-pointer text-sm leading-8 text-white bg-[#5F7ADA] border-none outline-none"
                      value={distributorFilter}
                      onChange={(e) => setDistributorFilter(e.target.value)}
                    >
                      <option value="last7Days">{t('adminRoute.dashboardPg.orders.date')}</option>
                      <option value="thisMonth">{t('adminRoute.dashboardPg.orders.thisMonth')}</option>
                      <option value="thisYear">{t('adminRoute.dashboardPg.orders.thisYear')}</option>
                    </select>
                  </div>
                </div> 
                <p className="text-white poppins text-lg leading-8 tracking-tight">
                {t('adminRoute.dashboardPg.distributors.count')}
                </p>
                <p className="text-sm poppins leading-8 tracking-tight">
                  {distributorData?.dateText}
                </p>
              </div>
            </div>

            <div className="w-full flex gap-3 p-0 bg-white rounded-lg">
              <ResponsiveContainer width="100%" height={220}>
                <AreaChart
                  width={400}
                  height={60}
                  data={graphData}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#5046E5"
                    fill="#8884d8"
                    strokeWidth={2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/* Right Container */}
          <div className="col-span-5 w-full h-full flex flex-col gap-4 p-4 rounded-lg bg-white shadow-2xl">
            <div className="flex justify-between items-center">
              <p className="text-[#1B1B1B] poppins text-xl leading-8 tracking-tight ">
              {t('adminRoute.dashboardPg.targetCompletion.title')}
              </p>
              <div className=" flex gap-1 items-center  ">
                <p className="poppins text-[#5046e5] leading-8 tracking-tight  ">
                {t('adminRoute.dashboardPg.targetCompletion.month')}
                </p>
                <KeyboardArrowDownIcon className="text-[#5046e5] font-light" />
              </div>
            </div>
            <div className="w-full bg-[#E2EEFF] px-4 py-1 rounded flex items-center justify-between ">
              <p className="poppins text-lg font-light">{t('adminRoute.dashboardPg.targetCompletion.achieved.label')}</p>
              <p className="poppins text-lg font-extralight">{t('adminRoute.dashboardPg.targetCompletion.achieved.value')}</p>
            </div>
            <div className="w-full items-center flex justify-center">
              <DonutChart
                data={[
                  {
                    label: "Completed",
                    value: 82,
                  },
                ]}
                colors={["#9CD27B"]}
                height={180}
                width={180}
                legend={false}
              />
            </div>
            <div className="w-full bg-[#E2EEFF] px-4 py-1 rounded flex items-center justify-between ">
              <p className="poppins text-lg font-light">{t('adminRoute.dashboardPg.targetCompletion.target.label')}</p>
              <p className="poppins text-lg font-extralight">{t('adminRoute.dashboardPg.targetCompletion.target.value')}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    {/* <CustomTableHeadCell>ORDER NUMBER</CustomTableHeadCell> */}
                    {/* <CustomTableHeadCell>DEALER NAME</CustomTableHeadCell> */}
                    <CustomTableHeadCell> {t('adminRoute.dashboardPg.table.distributorName')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.dashboardPg.table.address')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.dashboardPg.table.price')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.dashboardPg.table.status')}</CustomTableHeadCell>
                    <CustomTableHeadCell>{t('adminRoute.dashboardPg.table.orderDate')}</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {orders.map((order: any) => {
                    return (
                      <CustomTableBodyRow
                        sx={{
                          marginBottom: "10px !important",
                        }}
                        key={order?._id}
                      >
                        {/* <CustomTableBodyCell
                          sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          {order.skuName}
                        </CustomTableBodyCell> */}
                        {/* <CustomTableBodyCell>
                          {order?.skuCode}
                        </CustomTableBodyCell> */}
                        <CustomTableBodyCell>
                          {order?.distributor?.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.address?.name}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.totalPrice}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {order?.status?.toUpperCase()}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell>
                          {moment(order?.createdAt).format("DD.MM.YYYY")}
                        </CustomTableBodyCell>
                        <CustomTableBodyCell
                          sx={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        ></CustomTableBodyCell>
                      </CustomTableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="w-full flex justify-end">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </div>
      </div>
    </Sidebar>
  );
};

export default Dashboard;
