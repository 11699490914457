import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDd-S1XYiN-_f75KhZSVS0cCh5uQCo2woc",
  authDomain: "airobotics-8e460.firebaseapp.com",
  projectId: "airobotics-8e460",
  storageBucket: "airobotics-8e460.appspot.com",
  messagingSenderId: "1006910166446",
  appId: "1:1006910166446:web:568626152742f6062d643f",
  measurementId: "G-EHSL1N81FM",
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
