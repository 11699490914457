import { createTheme } from "@mui/material";

const Theme = createTheme({
    palette:{
        primary:{
            main:"#719FE5"
        },
        secondary:{
            main:"#EBEDEF"
        },
        success:{
            main:"#51CC8A"
        },
        danger:{
            main:"#EF376E"
        },
        warning:{
            main:"#FFCC00"
        },
        info:{
            main:"#747AF2"
        }
        
    },
    typography:{
        h6:{
            color:"#ffffff",
            fontSize:"1rem",
            fontWeight:"400"
            
        }
    }
})

export default Theme;