import React, { useState } from "react";
import { Box } from "@mui/material";

import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { adminFunctions } from "../../../constants/FunctionConstants";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
  modules: string[];
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const AddRole = ({ checked, setChecked, setOpen }: any) => {
  const [loading, setLoading] = useState(false);
const {t} = useTranslation()

  const moduleOptions = adminFunctions?.map((item) => {
    return {
      id: item.name,
      label: item.name,
    };
  });

  const defaultValues: FormProps = {
    name: "",
    modules: [],
  };

  const onSubmit = async (values: FormProps) => {
    values.modules = values.modules.map((item: any) => {
      return item.id;
    });

    try {
      setLoading(true);
      const { data } = await callAxios("post", "role/createRole", values);
      console.log(data);
      toastMessage("Role Created Successfully", "success");
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="flex flex-col gap-4  w-full">
        <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        {t('adminRoute.usersPage.createNewRole.pageTitle')} 
        </h3>
        <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
        {t('adminRoute.usersPage.createNewRole.pageDescription')}         </p>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full flex flex-col gap-8">
            <div className="w-full">
              <TextFieldElement
                name="name"
                label={t('adminRoute.usersPage.createNewRole.roleNameLabel')}  
                required
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^(?=.*[a-zA-Z]).+$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Name must contains a character. ";
                    }
                  },
                }}
              />
            </div>
            <div className="w-full">
              <AutocompleteElement
                name="modules"
                label={t('adminRoute.usersPage.createNewRole.chooseModulesLabel')} 
                required
                multiple
                options={moduleOptions}
                autocompleteProps={{
                  sx: {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </div>
            <button className="rounded bg-primary py-2 px-6 poppins text-white w-1/2 ">
            {t('adminRoute.usersPage.createNewRole.submitButton')} 
            </button>
          </div>
        </FormContainer>
      </div>
    </Box>
  );
};

export default AddRole;
