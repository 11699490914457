import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import GeneralDetails from "./CreateForm/GeneralDetails";
import AddressDetails from "./CreateForm/AddressDetails";
import PaymentDetails from "./CreateForm/PaymentDetails";
import { distributorFunctions } from "../../../constants/FunctionConstants";
import { useTranslation } from "react-i18next";

const CreateDistributor = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  console.log(formData);

  const finalSubmit = async (formValues: any) => {
    const values = { ...formData, ...formValues };
    values.function_ = distributorFunctions.map((item: any) => item.name);
    console.log(values);

    values.countryCode = formValues.countryCode.id;

    try {
      setLoading(true);

      const { data } = await callAxios(
        "post",
        "user/createDistributor",
        values
      );
      console.log(data);
      toastMessage("Distributor Created Successfully", "success");
      setLoading(false);
      navigate("/admin/all-distributors");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t(
                  "adminRoute.distributorsPage.onboardNewDistributor.pageTitle"
                )}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t(
                  "adminRoute.distributorsPage.onboardNewDistributor.pageDescription"
                )}{" "}
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-between py-2 rounded-lg px-8 align-items bg-white shadow-xl ">
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 1 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  1
                </p>
                <p className="poppins font-medium ">
                  {t(
                    "adminRoute.distributorsPage.onboardNewDistributor.generalDetailsStep.stepLabel"
                  )}{" "}
                </p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 2 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  2
                </p>
                <p className="poppins font-medium ">
                  {" "}
                  {t(
                    "adminRoute.distributorsPage.onboardNewDistributor.addressDetailsStep.stepLabel"
                  )}{" "}
                </p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 3 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  3
                </p>
                <p className="poppins font-medium ">
                  {" "}
                  {t(
                    "adminRoute.distributorsPage.onboardNewDistributor.paymentDetailsStep.stepLabel"
                  )}{" "}
                </p>
              </div>
            </div>
            {step === 1 && (
              <GeneralDetails
                step={step}
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {step === 2 && (
              <AddressDetails
                step={step}
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {step === 3 && (
              <PaymentDetails
                step={step}
                setStep={setStep}
                formData={formData}
                setFormData={setFormData}
                finalSubmit={finalSubmit}
              />
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateDistributor;
