import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
};

const CreateCategory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const defaultValues: FormProps = {
    name: "",
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);
    try {
      setLoading(true);
      const { data } = await callAxios(
        "post",
        "category/createCategory",
        values
      );
      console.log(data);
      toastMessage("Category Created Successfully", "success");
      setLoading(false);
      navigate("/admin/product-category");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.createCategoryPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.createCategoryPage.description')}              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded">
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full grid grid-cols-2 gap-16 ">
                  <div className="w-full flex flex-col gap-4">
                    <h6 className="text-[#23282D] text-2xl poppins font-medium">
                    {t('adminRoute.createCategoryPage.formContainer.createCategorySection.title')} 
                    </h6>
                    <TextFieldElement
                      name="name"
                      placeholder={t('adminRoute.createCategoryPage.formContainer.createCategorySection.formLabels.name')} 

                      required
                      fullWidth
                    />
                    <button
                      className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
                      type="submit"
                    >
              {t('adminRoute.createCategoryPage.formContainer.createCategorySection.submitButton')} 

                    </button>
                  </div>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateCategory;
