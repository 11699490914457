import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import ModalImage from "react-modal-image";
import { useTranslation } from "react-i18next";

type FormProps = {
  resolution: string;
  status: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const ViewTicket = ({
  open,
  setOpen,
  checked,
  setChecked,
  existingData,
}: any) => {
  const [loading, setLoading] = useState(false);
  console.log(existingData);

  const { t } = useTranslation();

  const defaultValues: FormProps = {
    resolution: existingData?.resolution || "",
    status: "closed",
  };

  const onSubmit = async (values: FormProps) => {
    const payload = {
      resolution: values.resolution,
      status: "closed",
    };
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        `support/updateTicket/${existingData?._id}`,
        payload
      );
      toastMessage("success", data.message);
      setLoading(false);
      setOpen(false);
      setChecked(!checked);
    } catch (error) {
      setLoading(false);
      toastMessage("error", error.response.data.message);
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-4  w-full">
          <h5 className="text-[#23282D] text-center poppins text-2xl font-medium leading-9 tracking-tight">
            {t("adminRoute.viewTicket.title")}
          </h5>

          <div className="w-full flex justify-between">
            <div className="flex flex-col gap-2">
              <h6 className="text-gray-600 poppins text-xl font-medium">
                {t("adminRoute.viewTicket.fields.title")}
              </h6>
              <p className="text-gray-400 poppins text-base ">
                {existingData?.title}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h6 className="text-gray-600 poppins text-xl font-medium">
                {t("adminRoute.viewTicket.fields.category")}
              </h6>
              <p className="text-gray-400 poppins text-base ">
                {existingData?.category}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h6 className="text-gray-600 poppins text-xl font-medium">
              {t("adminRoute.viewTicket.fields.description")}
            </h6>
            <p className="text-gray-400 poppins text-base ">
              {existingData?.description}
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h6 className="text-gray-600 poppins text-xl font-medium">
              {t("adminRoute.viewTicket.fields.images")}
            </h6>
            <div className="flex gap-4 px-4">
              {existingData?.images.map((image: any) => {
                return (
                  <div className="w-20 h-20 rounded cursor-pointer overflow-hidden">
                    <ModalImage small={image} large={image} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h6 className="text-gray-600 poppins text-xl font-medium">
              {t("adminRoute.viewTicket.fields.resolution")}
            </h6>
            <TextFieldElement
              name="resolution"
              placeholder={t("adminRoute.viewTicket.placeholders.resolution")}
              multiline
              rows={4}
              required
              disabled={existingData?.status === "closed"}
            />
          </div>
          <div className="w-full flex justify-center">
            {existingData?.status !== "closed" && (
              <button
                type="submit"
                className="rounded bg-[#719FE5] py-2 px-16 poppins text-white"
              >
                {t("adminRoute.viewTicket.buttons.closeTicket")}
              </button>
            )}
          </div>
        </div>
      </FormContainer>
    </Box>
  );
};

export default ViewTicket;
