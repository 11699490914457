import React, { useState, useEffect } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

type FormProps = {
  marginForDistributor: number;
  marginForDealer: number;
};

const PricingDetails = ({ step, setStep, formData, setFormData,existingData }: any) => {
  const defaultValues: FormProps = {
    marginForDistributor: existingData?.marginForDistributor || "",
    marginForDealer: existingData.marginForDealer,
  };
  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        Pricing Details
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="marginForDistributor"
                placeholder="Margin For Distributor"
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="marginForDealer"
                placeholder="Margin For Dealer"
                required
                type="number"
                fullWidth
              />
            </div>
          </div>
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            Next
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default PricingDetails;
