import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";

type FormProps = {
  name: string;
  location: string;
  phone: number;
  createdBy: string;
  createdAt: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const ViewDistributer = ({ checked, setChecked, setOpen, existingUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([] as any);
  console.log("existingUser",existingUser)
  const defaultValues: FormProps = {
    name: existingUser?.name,
    location: existingUser?.distributor?.billingCity,
    phone: existingUser?.phone,
    createdBy: existingUser?.createdBy?.name,
    createdAt: existingUser?.createdAt,
  };



  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="flex flex-col gap-4  w-full">
        <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          View Distributer
        </h3>
        <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
          Gain insights and details about user profiles !       </p>
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Distributer Name:</strong> <br />{existingUser?.name}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Owner's First Name:</strong><br /> {existingUser?.distributor?.ownerFirstName}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Owner's Last Name:</strong><br /> {existingUser?.distributor?.ownerLastName}
            </Typography>
            </div>
          </div>

          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Phone:</strong> <br/> {existingUser?.phone}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Email:</strong> <br/> {existingUser?.email}
            </Typography>
            </div>
          </div>


        </div>

        <h4 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          shipping address
        </h4>

        <div className="flex flex-col gap-4 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Address:</strong> <br/>  {existingUser?.distributor?.shippingAddress}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Zip Code:</strong>  <br/> {existingUser?.distributor?.shippingAddress}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>City:</strong> <br/> {existingUser?.distributor?.shippingCity}
            </Typography>
            </div>
          </div>

          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>State:</strong> <br/> {existingUser?.distributor?.shippingState}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Country:</strong> <br/> {existingUser?.distributor?.shippingCountry}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Region:</strong> <br/> {existingUser?.distributor?.shippingRegion}
            </Typography>
            </div>
          </div>


        </div>

        <h4 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          billing address
        </h4>

        <div className="flex flex-col gap-4 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Address:</strong> <br/>   {existingUser?.distributor?.billingAddress}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Zip Code:</strong>  <br/> 
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>City:</strong> <br/>   {existingUser?.distributor?.billingCity}
            </Typography>
            </div>
          </div>

          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>State:</strong> <br/>  {existingUser?.distributor?.billingState}
            </Typography>
            </div>
            <div className="w-full flex flex-col gap-1">
            <Typography variant="body1">
              <strong>Country:</strong> <br/> {existingUser?.distributor?.billingCountry}
            </Typography>
            </div>
            
          </div>


        </div>

      </div>
    </Box>
  );
};

export default ViewDistributer;
